import React from 'react';

import MemberCard from 'components/directus/member-card/MemberCard';
import memberCardHook from 'hooks/member-card/member-card.hook';

import s from 'components/directus/member-card/MemberCard.scss';

export default (): JSX.Element => {
    const staff = memberCardHook();

    return (
        <div className={s.memberCards}>
            {staff.map(({ node }) => (
                <MemberCard staff={node} key={`member-card-${node.directusId}`}/>
            ))}
        </div>
    );
};
