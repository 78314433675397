import React from 'react';
import Img from 'gatsby-image';

import Map from 'components/common/map/Map';
import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import locationsHook from 'hooks/our-locations/our-locations.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './Locations.scss';

export default (): JSX.Element => {
    const locations = locationsHook();
    const { node } = getFieldBySlug('our-locations', sectionTitleHook());

    return (
        <>
            <SectionTitle title={node.heading} align="center" />

            <div className={s.locations}>
                {locations.map(({ node }) => (
                    <div className={s.locations__location} key={`addresses-${node.directusId}`}>
                        <div className={s.locations__content}>
                            <Markdown source={node.address} className={s.locations__address} container />
                        </div>
                        <div className={s.locations__map}>
                            <div className={s.locations__image}>
                                <Img fluid={node.image.localFile.childImageSharp.fluid} alt={`Photograph of ${node.name} location.`} />
                            </div>
                            <Map
                                mapProps={{
                                    center: [ Number(node.longitude), Number(node.latitude) ],
                                    zoom: 16,
                                    controls: {
                                        zoom: true,
                                        compass: false,
                                        pitch: false,
                                        position: 'top-right',
                                    },
                                    mapHeight: '15rem',
                                }}
                                markerProps={{
                                    lngLat: [ Number(node.longitude), Number(node.latitude) ],
                                    markerPopup: {
                                        markerTitle: node.name,
                                        markerDescription: node.address,
                                    },
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
