import React from 'react';
import slugify from 'react-slugify';

import Modal from 'components/common/modal/Modal';
import { Props } from './MemberCard.interface';

import s from './MemberCard.scss';

export default ({ staff }: Props): JSX.Element => {
    const department = slugify(staff.department.name);

    return (
        <div className={s.memberCard}>
            <Modal
                trigger={
                    <>
                        <div className={s.memberCard__image}>
                            <img
                                alt={`Profile of ${staff.full_name}`}
                                src={staff.profile_photo.data.full_url}
                            />
                        </div>
                        <div className={`${s.memberCard__body} ${s.memberCard__body}__${department}`}>
                            <h3 className={s.memberCard__staffName}>{staff.full_name}</h3>
                            <p className={s.memberCard__jobTitle}>{staff.job_title}</p>
                        </div>
                    </>
                }
            >
                <>
                    <div className={s.tooltip__imageBlock}>
                        <img
                            alt={`Profile of ${staff.full_name}`}
                            src={staff.profile_photo.data.full_url}
                            className={s.tooltip__imageBlock__image}
                        />
                    </div>
                    <div className={s.tooltip__content}>
                        <h3 className={s.tooltip__title}>
                            {staff.full_name}
                        </h3>
                        <span className={s.tooltip__jobTitle}>
                            {staff.job_title}
                        </span>
                        <ul className={s.tooltip__list}>
                            <li>
                                <b>Favourite Drink</b>: {staff.favourite_drink}
                            </li>
                            <li>
                                <b>Favourite TV Series</b>: {staff.favourite_tv_series}
                            </li>
                            <li>
                                <b>Three Things You Can&apos;t Live Without</b>: {staff.favourite_three_things}
                            </li>
                        </ul>
                    </div>
                </>
            </Modal>
        </div>
    );
};
