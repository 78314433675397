import React, { useState, CSSProperties } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Section from 'components/common/section/Section';
import AboutUsContent from 'components/directus/about-us-content/AboutUsContent';
import CtaPanel from 'components/directus/cta-panel/CtaPanel';
import Footnote from 'components/directus/footnote/Footnote';
import Locations from 'components/directus/locations/Locations';
import MagicNumbers from 'components/directus/magic-numbers/MagicNumbers';
import MeetTheTeam from 'components/directus/meet-the-team/MeetTheTeam';
import StaffReviews from 'components/directus/staff-reviews/StaffReviews';
import aboutUsHook from 'hooks/about-us-tab/about-us.hook';
import WindowResizeHook from 'hooks/resize/resize.hook';

import s from './AboutUsTabs.scss';

export default (): JSX.Element => {
    const aboutUs = aboutUsHook();
    const dimensions = WindowResizeHook();
    const [ tabIndex, updateTabIndex ] = useState<number>(0);

    const tabActiveStyle: CSSProperties = {
        backgroundColor: '#ffffff',
        color: '#00b1e3',
        border: '1px solid #d9e4ea',
        fontWeight: 'bold',
        borderRadius: 0,
        borderBottom: dimensions.width <= 500 ? '1px solid #d9e4ea' : 0,
    };

    return (
        <>
            <h2 className={s.title}>{aboutUs[ tabIndex ].node.title}</h2>

            <Tabs selectedIndex={tabIndex} onSelect={i => updateTabIndex(i)}>
                <TabList className={s.list}>
                    {aboutUs.map(({ node }, i): JSX.Element => (
                        <Tab key={`tabList-tab-${node.directusId}`} style={tabIndex === i ? tabActiveStyle : undefined}>
                            {node.title_in_tab}
                        </Tab>
                    ))}
                </TabList>

                <TabPanel>
                    <Section>
                        <AboutUsContent slug="about-us" />
                    </Section>
                    <Section bottom={50}>
                        <MagicNumbers />
                    </Section>
                    <Section bottom={30}>
                        <Locations />
                    </Section>
                    <Section>
                        <Footnote />
                    </Section>
                    <Section>
                        <CtaPanel slug="home-cta" />
                    </Section>
                </TabPanel>

                <TabPanel>
                    <Section>
                        <AboutUsContent slug="our-story" />
                    </Section>
                    <Section>
                        <CtaPanel slug="home-cta" />
                    </Section>
                </TabPanel>

                <TabPanel>
                    <Section>
                        <AboutUsContent slug="our-values" />
                    </Section>
                    <Section>
                        <CtaPanel slug="home-cta" />
                    </Section>
                </TabPanel>

                <TabPanel>
                    <Section>
                        <AboutUsContent slug="our-services" />
                    </Section>
                    <Section>
                        <CtaPanel slug="home-cta" />
                    </Section>
                </TabPanel>

                <TabPanel>
                    <Section>
                        <MeetTheTeam />
                    </Section>
                    <Section>
                        <StaffReviews />
                    </Section>
                    <Section>
                        <CtaPanel slug="home-cta" />
                    </Section>
                </TabPanel>

                <TabPanel>
                    <Section>
                        <AboutUsContent slug="join-our-team" />
                    </Section>
                    <Section>
                        <CtaPanel slug="home-cta" />
                    </Section>
                </TabPanel>
            </Tabs>
        </>
    );
};
