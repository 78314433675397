import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusMagicNumber, MagicNumberEdge } from './magic-numbers.interface';

export default (): MagicNumberEdge[] => {
    const { allDirectusMagicNumber } = useStaticQuery<AllDirectusMagicNumber>(graphql`
    query {
      allDirectusMagicNumber(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            sort
            slug
            icon
            heading
            subheading
            colour
          }
        }
      }
    }
  `);

    return allDirectusMagicNumber.edges;
};
