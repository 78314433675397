import React from 'react';
import camelcase from 'camelcase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import Img from 'gatsby-image';

import Markdown from 'components/common/markdown/Markdown';
import aboutUsHook from 'hooks/about-us-tab/about-us.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './AboutUsContent.interface';

import s from './AboutUsContent.scss';

export default ({ slug }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, aboutUsHook());

    if (slug === 'about-us') {
        return (
            <div className={`${s.aboutUsContent} ${s[ `aboutUsContent___${camelcase(slug)}` ]}`}>
                {node.content.map(({ slug, title, content, image, width }): JSX.Element => (
                    <div
                        className={`${s.aboutUsContent__item} ${s[ `aboutUsContent__item___${width}` ]}`}
                        key={slug}
                    >
                        {image && (
                            <Img
                                fluid={image.localFile.childImageSharp.fluid}
                                alt={title}
                                className={s.aboutUsContent__image}
                                fadeIn
                            />
                        )}
                        <div className={s.aboutUsContent__content}>
                            <Markdown className={s.aboutUsContent__title} source={title} container />
                            <Markdown source={content} container />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={`${s.aboutUsContent} ${s[ `aboutUsContent___${camelcase(slug)}` ]}`}>
            { node.content.map(({ slug, title, content, image, width, configuration }): JSX.Element => {
                const styles = {
                    width: s[ `aboutUsContent__item___${width}` ],
                    grey: configuration ? s.aboutUsContent__item___grey : '',
                };

                return (
                    <div
                        key={slug}
                        className={`${s.aboutUsContent__item} ${styles.width} ${styles.grey}`}
                    >
                        { title && <Markdown className={s.aboutUsContent__title} source={title} container /> }
                        { image && (
                            <Img
                                fluid={image.localFile.childImageSharp.fluid}
                                alt={title}
                                className={s.aboutUsContent__image}
                                fadeIn
                            />
                        )}
                        <Markdown
                            container
                            source={content}
                            listItemIcon={
                                <FontAwesomeIcon icon={faCheck} size="sm" aria-label="check icon." />
                            }
                        />
                    </div>
                );
            })}
        </div>
    );
};
