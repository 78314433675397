import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusStaff, MemberCardEdges } from './member-card.interface';

export default (): MemberCardEdges[] => {
    const { allDirectusStaff } = useStaticQuery<AllDirectusStaff>(graphql`
    query {
      allDirectusStaff(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            full_name
            job_title
            favourite_drink
            favourite_holiday_destination
            favourite_tv_series
            favourite_three_things
            department {
              name
            }
            profile_photo {
              data {
                full_url
              }
            }
          }
        }
      }
    }
  `);
    return allDirectusStaff.edges;
};
