import React from 'react';

import trustpilotBannerHook from 'hooks/trustpilot-banner/trustpilot-banner.hook';

import s from './Footnote.scss';

export default (): JSX.Element => {
    const { total, score } = trustpilotBannerHook().review;

    return (
        <div className={s.footnote}>
            <em>
                * Since early 2014 we have been the #1 rated company formation agents on Trustpilot,<br />
                the consumer review platform, with an Excellent rating of {score} out of 5, based on {total} reviews.
            </em>
        </div>
    );
};
