import React from 'react';
import camelcase from 'camelcase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import magicNumbersHook from 'hooks/magic-numbers/magic-numbers.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome';
import { MagicItemProps, DividerProps } from './MagicNumbers.interface';

import s from './MagicNumbers.scss';

export default (): JSX.Element => {
    const numbers = magicNumbersHook();
    const { node } = getFieldBySlug('magic-numbers', sectionTitleHook());

    return (
        <>
            <SectionTitle title={node.heading} align="center" />

            <div className={s.magicNumber__items}>
                {numbers.map(({ node }) => (
                    <div
                        key={`magic-number-${node.slug}`}
                        className={`${s.magicNumber__item} ${s[ `magicNumber__item___${camelcase(node.slug)}` ]}`}
                    >
                        <div className={s.magicNumber__icon} style={{ backgroundColor: node.colour }}>
                            <FontAwesomeIcon icon={getSolidFontAwesomeIcon(node.icon)} />
                        </div>

                        <div className={s.magicNumber__content}>
                            <h4 className={s.magicNumber__heading}><Markdown source={node.heading} /></h4>
                            <Markdown className={s.magicNumber__body} source={node.subheading} />
                        </div>
                    </div>
                ))}
                <div className={s.magicNumber__dividers}>
                    {numbers.map(({ node }) => (
                        <div
                            key={`magic-number-divider-${node.directusId}`}
                            className={`${s.magicNumber__divider} ${s[ `magicNumber__divider___${camelcase(node.slug)}` ]}`}
                            style={{ backgroundColor: node.colour }}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
